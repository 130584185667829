/*
    Fonts
*/

//PLAYFAIR DISPLAY

@font-face {
	font-family: 'butler_regular';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/butler_regular-webfont.woff2' ) format( 'woff2' ), url( '../fonts/butler_regular-webfont.woff' ) format( 'woff' );
}

@font-face {
	font-family: 'butler_bold';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/butler_bold-webfont.woff2' ) format( 'woff2' ), url( '../fonts/butler_bold-webfont.woff' ) format( 'woff' );
}

@font-face {
	font-family: 'butler_black';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/butler_black-webfont.woff2' ) format( 'woff2' ), url( '../fonts/butler_black-webfont.woff' ) format( 'woff' );
}

@font-face {
	font-family: 'butler_ultra_light';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/butler_ultra_light-webfont.woff2' ) format( 'woff2' ), url( '../fonts/butler_ultra_light-webfont.woff' ) format( 'woff' );
}

@font-face {
	font-family: 'butler_light';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/butler_light-webfont.woff2' ) format( 'woff2' ), url( '../fonts/butler_light-webfont.woff' ) format( 'woff' );
}

@font-face {
	font-family: 'roboto';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/roboto-regular-webfont.woff2' ) format( 'woff2' ), url( '../fonts/roboto-regular-webfont.woff' ) format( 'woff' );
}

@font-face {
	font-family: 'butler_medium';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/butler_medium-webfont.woff2' ) format( 'woff2' ), url( '../fonts/butler_medium-webfont.woff' ) format( 'woff' );
}

@font-face {
	font-family: 'butler_extrabold';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/butler_extrabold-webfont.woff2' ) format( 'woff2' ), url( '../fonts/butler_extrabold-webfont.woff' ) format( 'woff' );
}

@font-face {
	font-family: 'robotoblack';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/roboto-black-webfont.woff2' ) format( 'woff2' ), url( '../fonts/roboto-black-webfont.woff' ) format( 'woff' );
}

@font-face {
	font-family: 'mplusrounded';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/MPLUSRounded1c-Regular.ttf' ) format( 'TrueType' );
}

@font-face {
	font-family: 'mplusrounded-medium';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/MPLUSRounded1c-Medium.ttf' ) format( 'TrueType' );
}

@font-face {
	font-family: 'mplusrounded-thin';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/MPLUSRounded1c-Thin.ttf' ) format( 'TrueType' );
}

@font-face {
	font-family: 'mplusrounded-light';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/MPLUSRounded1c-Light.ttf' ) format( 'TrueType' );
}

@font-face {
	font-family: 'mplusrounded-extra-bold';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/MPLUSRounded1c-ExtraBold.ttf' ) format( 'TrueType' );
}

@font-face {
	font-family: 'mplusrounded-bold';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/MPLUSRounded1c-Bold.ttf' ) format( 'TrueType' );
}

@font-face {
	font-family: 'mplusrounded-black';
	font-style: normal;
	font-weight: normal;
	src: url( '../fonts/MPLUSRounded1c-Black.ttf' ) format( 'TrueType' );
}