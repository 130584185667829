.site-alert-banner {
  background-color: #332A2A;
  padding: 1rem;

  p {
    color: white;
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-family: $f-roboto-regular;

    a {
      color: white;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}