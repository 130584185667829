
/*
    Block title
*/

.block-title {
	box-sizing: border-box;
	margin-bottom: 2.5rem;
	margin-left: 50%;
	transform: translateX( -50% );
	width: 100vw;

	.jumbotron-bg {
		background: url( ../img/bg-global.jpg ) center center no-repeat;
		background-size: cover;
	}

	.jumbotron-beer-bg {
		background: url( ../img/beer/beer-title.jpg ) center center no-repeat;
		background-size: cover;
	}
}

.page-title {
	font-family: $f-mplusrounded-extra-bold;
	color:#282828;
}