
$f-fallback-heading: 'serif';
$f-butler-regular: 'butler_regular', $f-fallback-heading;
$f-butler-bold: 'butler_bold', $f-fallback-heading;
$f-butler-black: 'butler_black', $f-fallback-heading;

$f-fallback-content: 'sans-serif';
$f-butler-ultra_light: 'butler_ultra_light', $f-fallback-content;
$f-butler-light: 'butler_light', $f-fallback-content;
$f-roboto-regular: 'roboto', $f-fallback-content;
$f-butler-medium: 'butler_medium', $f-fallback-content;
$f-butler-extrabold: 'butler_extrabold', $f-fallback-content;
$f-roboto-black: 'robotoblack', $f-fallback-content;


$f-mplusrounded: 'mplusrounded';
$f-mplusrounded-medium :'mplusrounded-medium', $f-mplusrounded;
$f-mplusrounded-thin :'mplusrounded-thin', $f-mplusrounded;
$f-mplusrounded-light :'mplusrounded-light', $f-mplusrounded;
$f-mplusrounded-extra-bold :'mplusrounded-extra-bold', $f-mplusrounded;
$f-mplusrounded-bold :'mplusrounded-bold', $f-mplusrounded;
$f-mplusrounded-black :'mplusrounded-black', $f-mplusrounded;
