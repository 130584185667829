.title-static-page {
	font-family: $f-mplusrounded-extra-bold;
	font-size: 25px;
	margin-bottom: 0rem !important;
}

.sub-title-static-page {
	font-family: $f-mplusrounded-extra-bold;
	font-size: 18px;
	padding-top: 1.8rem;
}

.ul-bbq {
	padding-left: 1.2rem;

	li {
		font-family: $f-roboto-regular;
		font-size: 16px;
		line-height: 40px;

		&.line-height-simple {
			line-height: 20px;
		}
	}

	&.column-count-2 {
		column-count: 2;
	}
}

.security-p-bbq {
	font-family: $f-roboto-regular;
}

.roboto-p-bbq, .roboto-p-bags {
	font-family: $f-roboto-regular;
	font-size: 16px;
}

.roboto-p-bbq {
	font-weight: bold !important;
}

.link-bags {
	color: #981710;

	a {
		color: #981710;
	}
}