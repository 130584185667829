/*
    Store address header
*/

.store__selection {

	.ss__address {
		color: rgba( black, 0.5 );
		font-size: 0.9rem;

		.change-shop {
			color: #fff;
			text-decoration: none;
			font-size: 14px;
			background-color: #DF271C;
			padding: 0.225rem 1.25rem;

			&:hover {
				text-decoration: none;
			}
		}

		a {
			color: $primary;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

}


