.dropdown-menu {
  background-color: #FFFFFF;
  opacity: 1;
  margin: 0;
  border-radius: 0px;
  border: unset;
  width: 100%;
  color:black;

    @media (max-width: 991px) {
      text-align: center;
    }
}