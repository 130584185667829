.form-search {

	input {
		background-color: #981710;
		border: 0;
		border-radius: 2rem 0 0 2rem;
		color: white;
		font-family:$f-roboto-regular;
		font-size: 15px;
		height: 40px;

		&::placeholder {
			color: white;
		}

		&:active {
			&::placeholder {
				color: $primary;
			}
		}

		&:focus {
			background-color: #981710;
			color: white;
			font-size: 15px;
		}
	}

	.btn {
		background-color: #76120C;
		border: 0;
		border-radius: 0 2rem 2rem 0;
		display: flex;
		justify-content: center;
		height: 40px;
		width: 55px;
		position: absolute;
		align-items: center;
		right: 0;

		&:hover,&:focus {
			background-color: #76120C;
			color: white;
		}

		img {
			height: 1.2rem;
			margin-left: 0.25rem;
			margin-right: 0.5rem;
			width: 1.2rem;
		}


	}
}