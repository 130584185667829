.main-menu {
	display: flex;
	justify-content: space-between;
	width: 100%;
	color: $white;

	.nav-link {
		background-color: transparent;
		color: $white;
		font-family: $f-mplusrounded-extra-bold;
		padding-bottom: 1rem;
		padding-top: 1rem;
		transition: all ease 0.5s;
		text-transform: uppercase;

		&:hover {
			background-color: darken( $primary, 10% );
		}
	}

	.nav-option {
		color: #981710;
		font-family: $f-roboto-regular;
		font-size: 16px;
		text-transform: none;


		&:hover {
			background-color: darken( $primary, 10% );
			color: $white;
		}

	}

}

.main-menu-modal {
	background-color: $primary;
	font-size: 1.6rem;
	height: 100vh;
	width: 100vw;

	.nav-item {
		display: block;
		width: 100%;
		color: $white;
	}

	.nav-link {
		background-color: transparent;
		color: $black;
		padding-bottom: 0.5rem;
		padding-top: 0.5rem;
		text-transform: uppercase;
		transition: all ease 0.5s;

		&:hover {
			background-color: darken( $primary, 10% );
		}
	}

	.main-menu {

		.nav-link {
			font-family: $f-roboto-black;
		}
	}

	.secondary-menu {

		.nav-link {
			font-family: $f-butler-medium;
			font-size: 1.2rem;
		}
	}

	.form-control {
		padding: 1.5rem;
	}

	hr {
		background-color: $white;
		width: 15%;
	}

	.close {
		opacity: 1;
		position: absolute;
		right: 1rem;
		top: 1rem;
		z-index: 10;
	}
}

.page__content {
	padding-bottom: 2rem;

	@include media-breakpoint-up(md) {
		background: #f8f9fa;
		padding-bottom: 5rem;
	}
}

.fp__main-button {
	background-color: #DF271C;
	border-color: #DF271C;
}

.fp__main-button:hover {
	background-color: #981710;
	border-color: #981710;
}

.fp__main-button:not(:disabled):not(.disabled):active {
	background-color: #981710;
	border-color: #981710;
}

.fp__main-button:focus {
	box-shadow: $pagination-focus-box-shadow;
}

.fp__main-button:not(:disabled):not(.disabled):active:focus {
	box-shadow: $pagination-focus-box-shadow;
}

.covid-banner {
	padding-top: 2rem;

	@include media-breakpoint-up(md) {
		padding-top: 0rem;
	}
}
