/*
    Recipes Section
*/

.recipes {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	background: url( /dist/img/Bk-Recette.jpg ) center center no-repeat;
	background-size: cover;
	padding: 60px 0;
	height: 1px;
	min-height: 550px;
	text-align: center;
	margin: 0 auto 0 auto;
	padding: 0 0 35px 0;

	@include media-breakpoint-up(lg) {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		background: url( /dist/img/Bk-Recette.jpg ) center center no-repeat;
		background-size: cover;
		padding: 60px 0;
		height: 1px;
		min-height: 550px;
		text-align: center;
	}

	.btn {
		padding-left: 3rem;
	}

	.r__title {
		font-size: 38px;
		margin: 0 auto;
		padding-bottom: 1.5rem;
		max-width: 700px;
		font-family: $f-mplusrounded-black;

		+ p {
			margin-top: 0;
		}
	}

	.r__description {
		font-size: 24px;
	}

	.btn[target=_blank] {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding-right: 50px;

		&:after {
			content: '';
			display: block;
			background: url("../../dist/img/icons/external-link-white.svg");
			background-size: cover;
			width: 25px;
			height: 25px;
			margin-left: 10px;
		}

		&:hover, &:focus {
			&:after {
				background: url("../../dist/img/icons/external-link-white.svg");
			}
		}

		&:not([class*='outline']) {
			&:after {
				background: url("../../dist/img/icons/external-link-white.svg");
			}
		}
	}

	.icon-button {
		box-shadow: none;
		transition: all .3s;

		&:hover, &:focus {
			box-shadow: 0 0 20px $gray-500;
			transition: all .3s;
			text-decoration: none;
		}
	}

	.container-thumbnails {
		text-align: center;
	}

	.img-thumbnail-link {
		display: inline-block;
		padding: 5px;
	}

	.img-border {
		border-color: $gray-500;
		border-radius: 50%;
		border-style: solid;
		border-width: 1px;
		opacity: 0.57%;
	}

}


