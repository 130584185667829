/*
   Lead text
*/

.lead {
	@extend .border-bottom;
	font-family: $f-butler-light;
	margin-bottom: 3rem;
	padding-bottom: 3rem;
	font-weight: bold;
}

.lead-index {
	@extend .border-bottom;
	font-family: $f-butler-light;
	padding-bottom: 2rem;
	font-weight: bold;
}
