/*
* Stores page
*/

.stores {

	.s__map {

		td {
			border-top: 0;
		}
	}

	.s__directions {

		div {
			margin: 0 auto;
		}
	}

	.store__phone {
		white-space: nowrap;
	}

	.search-stores-map {
		font-family: 'mplusrounded-medium';;

		.btn {
			background-color: #89251B;
			color:white;
			width: 59px;
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 20px;
				height: 20px;
			}
		}

		input {
			border: solid 2px #6a0404;
			border-radius: 24px 0 0 24px;
			height: 50px;

			&::placeholder { /* Chrome/Opera/Safari */
				color: #AAAAAA;
				font-family: $f-roboto-regular;
				font-style: italic;
				font-size: 14px;
				font-weight: normal;
			}
			&::-moz-placeholder { /* Firefox 19+ */
				color: #AAAAAA;
				font-family: $f-roboto-regular;
				font-style: italic;
				font-size: 14px;
				font-weight: normal;
			}
			&:-ms-input-placeholder { /* IE 10+ */
				color: #AAAAAA;
				font-family: $f-roboto-regular;
				font-style: italic;
				font-size: 14px;
				font-weight: normal;
			}
			&:-moz-placeholder { /* Firefox 18- */
				color: #AAAAAA;
				font-family: $f-roboto-regular;
				font-style: italic;
				font-size: 14px;
				font-weight: normal;
			}

		}

		h2, h5 {
			font-family: 'mplusrounded-black'
		}

		hr {
			border-top: 1px solid black;
		}

		.lead {
			@extend .border-bottom;
			margin-bottom: 1rem;
			padding-bottom: 1rem;

			p {
				font-size: 14px;
				margin-bottom: 0rem;
				padding-bottom: 0rem;
				font-weight: normal;
			}
		}
	}
	.search-stores-map .svg{
		width: 26px;
		height: auto;
		margin-right: 10px;
		margin-top: 15px;
		display: inline;
	}

	.search-stores-map .svg-legende{
		width: 32px;
		height: 32px;
		margin: 15px auto 0 auto;
		display:block;

		@include media-breakpoint-up(md) {
			margin: 15px 10px 0 0;
		}
	}

	.search-stores-map .btn-choisir{
		bottom:3px;
		right:3px;
		background-color: #DF271C;
		font-family: $f-mplusrounded-medium;
		height: 27px;
		font-size: 14px;
		padding: 2px 20px;
		margin: 15px 0 0 0;
		width: auto;

		@include media-breakpoint-up(md) {
			position: absolute;
			margin: 0;
			bottom: 0;
			right: 15px;
		}

	}

	.search-stores-map .btn-choisir:hover {
		background-color: #981710;
	}

	.search-stores-map .btn-choisir-active {
		bottom:3px;
		right:3px;
		background-color: #020202;
		border-color:#020202;
		box-shadow: none;
		color:white;
		font-family: $f-mplusrounded-medium;
		height: 27px;
		font-size: 14px;
		padding: 2px 20px;
		margin: 15px 0 0 0;
		width: auto;

		@include media-breakpoint-up(md) {
			position: absolute;
			margin: 0;
			bottom: 0;
			box-shadow: none;
			right: 15px;
		}
	}


	.store-legend {
		display: flex;
		padding-right: 0;

		.store-legend__child {
			padding: 0 10px;
			@include media-breakpoint-up(md) {
				margin: 0;
				padding: 0 0 0 20px;
			}

			span {
				font-size: 12px;
				text-align: center;
				@include media-breakpoint-up(md) {
					text-align: left;
				}
			}
		}
	}

	.store-legend-container {
		display: flex;
		align-items: flex-end;
		padding-left: 0rem;
	}

	.btn-store-hours {
		background-color: transparent;
		border: 0;
		padding: 0;
		color: #DF271C;
		outline: none;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-family: $f-mplusrounded-extra-bold;
		font-size: 15px;

		&::after {
			content: "";
			border-right: 1px solid #DF271C;
			border-bottom: 1px solid #DF271C;
			width: 10px;
			height: 10px;
			transform: rotate(45deg);
			position: relative;
			margin-left: 10px;
			margin-top: -3px;
		}

		&[aria-expanded=true] {
			&::after {
				transform: rotate(-135deg);
				margin-top: 6px;
			}
		}

		&:hover, &:focus {
			border: 0;
			outline: none;
		}

	}

	.store-hours-list {
		ul {
			list-style: none;
			font-size: 14px;
			padding: 0;
			margin: 0;

			li {
				background-color: #F2F2F2;
				margin: 0 0 2px 0;
				padding: 5px 30px 5px 20px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				span {
					font-family: $f-roboto-regular;
				}
			}
		}
	}
	.store-distance {
		position: absolute;
		top: 0;
		right: 0;
		width: auto;
		font-size: 18px;
		font-family: $f-mplusrounded-medium;
		text-align: right;

		@include media-breakpoint-up(md) {
			position: relative;
			width: 100%;
			font-size: 18px;
		}

	}
	.store-found {
		font-family: $f-roboto-regular;
		font-weight: bold;
		font-size: 20px;
	}

	.store-phone {
		text-decoration: none;
		color: #212529;
	}

	.store-hours-list {
		@include media-breakpoint-up(md) {
			padding-right: 1.5rem;
		}
	}

	.social-networks-mobile {
		padding-top: 1rem;

		@include media-breakpoint-up(md) {
			padding-top: 0;
		}
	}

}


.pagination-ma {
	display: flex;
	@include list-unstyled();
}

.page-link-ma {
	position: relative;
	display: block;
	font-family: $f-mplusrounded-medium;
	font-size: 16px;
	padding: $pagination-padding-y $pagination-padding-x;
	margin-left: -$pagination-border-width;
	line-height: $pagination-line-height;
	color: #000000;
	background-color: $pagination-bg;

	&:hover {
		z-index: 2;
		text-decoration: none;
		background-color: $pagination-hover-bg;
		border-color: $pagination-hover-border-color;
		color: #000000;
	}

	&:focus {
		z-index: 2;
		outline: $pagination-focus-outline;
		box-shadow: $pagination-focus-box-shadow;
	}
}

.page-item-ma {
	&:first-child {
		.page-link-ma {
			margin-left: 0;
			@include border-left-radius($border-radius);
		}
	}
	&:last-child {
		.page-link {
			@include border-right-radius($border-radius);
		}
	}

	&.active .page-link-ma {
		z-index: 2;
		text-decoration: none;
		background-color: $pagination-hover-bg;
		border-color: $pagination-hover-border-color;
		color: #000000;
	}

	&.disabled .page-link-ma {
		color: $pagination-disabled-color;
		pointer-events: none;
		// Opinionated: remove the "hand" cursor set previously for .page-link
		cursor: auto;
		background-color: $pagination-disabled-bg;
	}
}


//
// Sizing
//

.pagination-lg {
	@include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, $border-radius-lg);
}

.pagination-sm {
	@include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, $border-radius-sm);
}
